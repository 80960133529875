import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import imagePath1 from "../img/v.png";
import imagePath2 from "../img/v2.png";
import imagePath3 from "../img/v3.png"; // 导入图片路径
import { motion } from "framer-motion";
import v1z from "../img/Compressed/v1z.jpg";
import v2z from "../img/Compressed/v2z.jpg";
import v3z from "../img/Compressed/v3z.jpg";
import v4z from "../img/Compressed/v4z.jpg";
import v5z from "../img/Compressed/v5z.jpg";
import J1 from "../img/Jaime.jpg";
import J2 from "../img/Jaime2.jpg";
import VIVO from "../img/VIVO.png";
//import video1 from "../img/vivojaimelee.mp4";
import video1 from "../img/Typhur.mp4";
import video2 from "../img/m1.mp4";
import video3 from "../img/voibonshadow.mp4";
import RARONE from "../img/RARONE.jpeg";
//import VT from "../img/Typhur.mp4"; // 导入图片路径
function NewHeader() {
  // Styles for the header
  const headerStyle = {
    backgroundColor: "#1F1F1F",
    height: "60px",
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    color: "#fff",
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 1000,
  };

  const backButtonStyle = {
    color: "#fff",
    textDecoration: "none",
    marginRight: "20px",
    width: 45,
  };

  const downloadButtonStyle = {
    position: "fixed",
    right: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "30px",
    padding: "0 15px",
    backgroundColor: "#333", // Blue background color
    color: "#fff", // White text color
    border: "none", // No border
    borderRadius: "5px", // Rounded corners
    cursor: "pointer", // Pointer cursor on hover
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Slight shadow for depth
    transition: "background-color 0.3s", // Smooth transition for hover effect
    fontSize: "16px", // Font size
    fontWeight: "bold", // Bold font
  };
  const downloadTextStyle = {
    position: "fixed",
    right: "100px", // Adjust this value as needed to position the text
    height: "30px",
    display: "flex",
    alignItems: "center",
    color: "#fff", // White text color
    fontSize: "16px", // Font size
  };

  // Button style inside the button element
  const buttonInnerStyle = {
    backgroundColor: "transparent",
    border: "none",
    color: "inherit",
    padding: 0,
    cursor: "pointer",
    height: "100%", // Ensure the button fills the container height
  };

  // Style for the outermost div
  const outerDivStyle = {
    height: "60px",
    width: "100%",
    display: "flex",
    alignItems: "center", // This ensures vertical centering
    justifyContent: "space-between", // This spreads out the span and button div
    padding: "0 20px", // Adjust padding as needed
  };

  const downloadLinkRef = useRef(null);

  // Assuming you have another URL along with imagePath1
  const DownloadUrl = imagePath1;
  const anotherUrl = imagePath2; // Replace with your second file URL
  const anotherUrl3 = imagePath3; // Replace with your second file URL

  const handleDownloadLogo = () => {
    if (!DownloadUrl || !anotherUrl) {
      //console.error("URLs are not defined.");
      return;
    }

    // Function to create and click a download link
    const downloadFile = (url, filename) => {
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link); // Append to body
      link.click(); // Simulate click
      document.body.removeChild(link); // Remove from body
    };

    // Download first file
    downloadFile(DownloadUrl, "李佳奇作品集Visual1");

    // Download second file
    downloadFile(anotherUrl, "李佳奇作品集Visual2"); // Replace 'AnotherFile' with your desired filename
    downloadFile(anotherUrl3, "李佳奇作品集Visual3"); // Replace 'AnotherFile' with your desired filename
  };

  return (
    <div style={headerStyle}>
      <Link to="/" style={backButtonStyle}>
        <motion.div
          whileHover={{ scale: 1.15 }} // 鼠标悬停时缩放到1.2倍
          whileTap={{ scale: 0.85 }}
        >
          返回
        </motion.div>
      </Link>
      <div style={outerDivStyle}>
        <span style={downloadTextStyle}>本页HD作品集</span>
        <div style={downloadButtonStyle}>
          <button onClick={handleDownloadLogo} style={buttonInnerStyle}>
            下载
          </button>
        </div>
      </div>
      <a
        href={downloadLinkRef}
        style={{ display: "none" }}
        aria-label="Download"
      />
    </div>
  );
}
function Visual() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // 在组件加载时滚动到页面顶部
    window.scrollTo(0, 0);
  }, []);

  const maxWidth = 1048;
  const widthPercentage = 94;
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  );

  const imageStyle = {
    maxWidth: `${maxWidth}px`,
    width: `${calculatedWidth}px`,
    height: "auto", // 高度自动适应宽度
  };

  const testStyle = {
    cursor: "pointer",
    color: "#fff",
    borderRadius: "5px",
    textAlign: "center",
    whiteSpace: "nowrap",
    fontFamily: "SF",
    fontWeight: 400,
  };
  const lineStyle = {
    cursor: "pointer",
    color: "#666",
    borderRadius: "5px",
    textAlign: "center",
    fontFamily: "SF",
    fontWeight: 400,
  };

  // 创建单独的引用指向每张图片

  const imageV2z = useRef(null);
  const imageV3z = useRef(null);
  const imageV4z = useRef(null);
  const imageV5z = useRef(null);
  const imageVIVO = useRef(null);
  // 为每个链接创建滚动函数
  const scrollToImageV2z = () =>
    imageV2z.current?.scrollIntoView({ behavior: "smooth" });
  const scrollToImageV3z = () =>
    imageV3z.current?.scrollIntoView({ behavior: "smooth" });
  const scrollToImageV4z = () =>
    imageV4z.current?.scrollIntoView({ behavior: "smooth" });
  const scrollToImageV5z = () =>
    imageV5z.current?.scrollIntoView({ behavior: "smooth" });
  const scrollToImageVIVO = () =>
    imageVIVO.current?.scrollIntoView({ behavior: "smooth" });

  return (
    <div>
      <NewHeader />
      <div
        style={{
          position: "relative",
          top: 60,
          backgroundColor: "#1F1F1F",
          width: "100vw",
          height: "auto", // 高度自动适应图片的高度
          display: "flex",
          overflow: "hidden", // 隐藏溢出部分
          flexDirection: "column", // 垂直排列
          alignItems: "center", // 居中对齐
          justifyContent: "center", // 水平居中
        }}
      >
        <img src={v1z} alt="李佳奇视觉作品1" style={imageStyle} />
        <div // 导航栏
          style={{
            display: "flex",
            justifyContent: "center", // 水平居中子元素
            alignItems: "center", // 垂直居中（如果需要）
            width: "100%", // 父元素占满可用宽度
          }}
        >
          <div
            style={{
              backgroundColor: "#1F1F1F",
              maxWidth: `${maxWidth}px`,
              width: `${calculatedWidth}px`,
              height: "min(12vw,100px)", // 高度自动适应图片的高度

              alignItems: "center", // 垂直居中
              justifyContent: "center",
              overflow: "hidden", // 隐藏溢出部分
              display: "grid",
              gridTemplateColumns: "repeat(9, 1fr)", // 4等分网格
              position: "relative",
              padding: "0px 5vw",
              fontSize: "min(1.2vw, 10px)",
            }}
          >
            <motion.h1
              whileHover={{ scale: 1.1 }} // 鼠标悬停时缩放到1.2倍
              whileTap={{ scale: 0.9 }}
              onClick={scrollToImageV2z}
              style={testStyle}
            >
              腾讯虎虎生威IP
            </motion.h1>
            <h1 style={lineStyle}>/</h1>
            <motion.h1
              whileHover={{ scale: 1.1 }} // 鼠标悬停时缩放到1.2倍
              whileTap={{ scale: 0.9 }}
              onClick={scrollToImageV3z}
              style={testStyle}
            >
              欧美电商视觉
            </motion.h1>
            <h1 style={lineStyle}>/</h1>
            <motion.h1
              whileHover={{ scale: 1.1 }} // 鼠标悬停时缩放到1.2倍
              whileTap={{ scale: 0.9 }}
              onClick={scrollToImageV4z}
              style={testStyle}
            >
              3D渲染与CG平面
            </motion.h1>
            <h1 style={lineStyle}>/</h1>
            <motion.h1
              whileHover={{ scale: 1.1 }} // 鼠标悬停时缩放到1.2倍
              whileTap={{ scale: 0.9 }}
              onClick={scrollToImageVIVO}
              style={testStyle}
            >
              VIVO
            </motion.h1>
            <h1 style={lineStyle}>/</h1>
            <motion.h1
              whileHover={{ scale: 1.1 }} // 鼠标悬停时缩放到1.2倍
              whileTap={{ scale: 0.9 }}
              onClick={scrollToImageV5z}
              style={testStyle}
            >
              油画素面
            </motion.h1>
          </div>
        </div>
        <video controls src={video2} alt="Video" style={imageStyle} />
        <img
          ref={imageV2z}
          src={v2z}
          alt="李佳奇视觉作品2"
          style={imageStyle}
        />
        {/*        <video controls src={VT} alt="Video" style={imageStyle} /> */}
        <video controls src={video3} alt="Video" style={imageStyle} />
        <img
          ref={imageV3z}
          src={v3z}
          alt="李佳奇视觉作品3"
          style={imageStyle}
        />
        <img
          // ref={imageV5z}
          src={J1}
          alt="李佳奇视觉作品5"
          style={imageStyle}
        />
        <img
          // ref={imageV5z}
          src={J2}
          alt="李佳奇视觉作品5"
          style={imageStyle}
        />
        <video
          ref={imageVIVO}
          controls
          src={video1}
          alt="Video"
          style={imageStyle}
        />
        <img src={VIVO} alt="李佳奇视觉作品4" style={imageStyle} />
        <img
          // ref={imageV5z}
          src={RARONE}
          alt="李佳奇视觉作品5"
          style={imageStyle}
        />{" "}
        <img
          ref={imageV4z}
          src={v4z}
          alt="李佳奇视觉作品4"
          style={imageStyle}
        />
        <img
          ref={imageV5z}
          src={v5z}
          alt="李佳奇视觉作品5"
          style={imageStyle}
        />
      </div>
    </div>
  );
}

export default Visual;
