import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AppUIUX from "./components/appuiux";
import Ecommerce from "./components/ecommerce";
import Motion from "./components/motion";
import Visual from "./components/visual";
import Web from "./components/web";
import New from "./components/new";
import AIGC from "./components/AIGC";
import PackageIndustrial from "./components/PackageIndustrial";

const root = ReactDOM.createRoot(document.getElementById("root"));

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    // 这里是触发 Clarity 页面跟踪的地方
    // 例如: window.clarity("track", "PageView");
    //console.log('Page view tracked for:', location.pathname); // 用于测试的日志
  }, [location]);

  return null; // 该组件不渲染任何内容
};

root.render(
  <React.StrictMode>
    <Router>
      <TrackPageView />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/new" element={<New />} />
        <Route path="/appuiux" element={<AppUIUX />} />
        <Route path="/ecommerce" element={<Ecommerce />} />
        <Route path="/motion" element={<Motion />} />
        <Route path="/visual" element={<Visual />} />
        <Route path="/web" element={<Web />} />
        <Route path="/New" element={<New />} />
        <Route path="/aigc" element={<AIGC />} />
        <Route path="/packageindustrial" element={<PackageIndustrial />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
