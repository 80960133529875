import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import imagePath1 from "../img/uo.png"; // 导入图片路径
import { motion } from "framer-motion";
import u1z from "../img/Compressed/u1z.jpg";
import u2z from "../img/Compressed/u2z.jpg";
import u3z from "../img/Compressed/u3z.jpg";
import h1 from "../img/Compressed/h1.jpg";

// NewHeader Component
function NewHeader() {
  // Styles for the header
  const headerStyle = {
    backgroundColor: "#1F1F1F",
    height: "60px",
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    color: "#fff",
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 1000,
  };

  const backButtonStyle = {
    color: "#fff",
    textDecoration: "none",
    marginRight: "20px",
    width: 45,
  };

  const downloadButtonStyle = {
    position: "fixed",
    right: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "30px",
    padding: "0 15px",
    backgroundColor: "#333", // Blue background color
    color: "#fff", // White text color
    border: "none", // No border
    borderRadius: "5px", // Rounded corners
    cursor: "pointer", // Pointer cursor on hover
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Slight shadow for depth
    transition: "background-color 0.3s", // Smooth transition for hover effect
    fontSize: "16px", // Font size
    fontWeight: "bold", // Bold font
  };
  const downloadTextStyle = {
    position: "fixed",
    right: "100px", // Adjust this value as needed to position the text
    height: "30px",
    display: "flex",
    alignItems: "center",
    color: "#fff", // White text color
    fontSize: "16px", // Font size
  };

  // Button style inside the button element
  const buttonInnerStyle = {
    backgroundColor: "transparent",
    border: "none",
    color: "inherit",
    padding: 0,
    cursor: "pointer",
    height: "100%", // Ensure the button fills the container height
  };

  // Style for the outermost div
  const outerDivStyle = {
    height: "60px",
    width: "100%",
    display: "flex",
    alignItems: "center", // This ensures vertical centering
    justifyContent: "space-between", // This spreads out the span and button div
    padding: "0 20px", // Adjust padding as needed
  };

  const downloadLinkRef = useRef(null);

  // Assuming you have another URL along with imagePath1
  const DownloadUrl = imagePath1;
  //const anotherUrl = imagePath2; // Replace with your second file URL

  const handleDownloadLogo = () => {
    if (!DownloadUrl) {
      //console.error("URLs are not defined.");
      return;
    }

    // Function to create and click a download link
    const downloadFile = (url, filename) => {
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link); // Append to body
      link.click(); // Simulate click
      document.body.removeChild(link); // Remove from body
    };

    // Download first file
    downloadFile(DownloadUrl, "李佳奇作品集UIUX1");

    // Download second file
    // downloadFile(anotherUrl, '李佳奇作品集UIUX2'); // Replace 'AnotherFile' with your desired filename
  };

  return (
    <div style={headerStyle}>
      <Link to="/" style={backButtonStyle}>
        <motion.div
          whileHover={{ scale: 1.15 }} // 鼠标悬停时缩放到1.2倍
          whileTap={{ scale: 0.85 }}
        >
          返回
        </motion.div>
      </Link>
      <div style={outerDivStyle}>
        <span style={downloadTextStyle}>本页HD作品集</span>
        <div style={downloadButtonStyle}>
          <button onClick={handleDownloadLogo} style={buttonInnerStyle}>
            下载
          </button>
        </div>
      </div>
      <a
        ref={downloadLinkRef}
        href={downloadLinkRef.current}
        style={{ display: "none" }}
        aria-label="Download File"
      />
    </div>
  );
}

function AppUIUX() {
  const testStyle = {
    cursor: "pointer",
    color: "#fff",
    borderRadius: "5px",
    textAlign: "center",
    whiteSpace: "nowrap",
    fontFamily: "SF",
    fontSize: "1.5vw",
    fontWeight: 400,
  };
  const lineStyle = {
    cursor: "pointer",
    color: "#666",
    borderRadius: "5px",
    textAlign: "center",
    fontFamily: "SF",
    fontWeight: 400,
  };
  // 创建单独的引用指向每张图片
  const imageU2z = useRef(null);
  const imageU3z = useRef(null);
  const imageU4z = useRef(null);

  // 为每个链接创建滚动函数
  const scrollToImageU2z = () =>
    imageU2z.current?.scrollIntoView({ behavior: "smooth" });
  const scrollToImageU3z = () =>
    imageU3z.current?.scrollIntoView({ behavior: "smooth" });
  const scrollToImageU4z = () =>
    imageU4z.current?.scrollIntoView({ behavior: "smooth" });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // 在组件加载时滚动到页面顶部
    window.scrollTo(0, 0);
  }, []);

  const maxWidth = 1048;
  const widthPercentage = 94;
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  );

  const imageStyle = {
    maxWidth: `${maxWidth}px`,
    width: `${calculatedWidth}px`,
    height: "auto", // 高度自动适应宽度
  };

  return (
    <div>
      <NewHeader />
      <div
        style={{
          position: "relative",
          top: 60,
          backgroundColor: "#1F1F1F",
          width: "100vw",
          height: "auto", // 高度自动适应图片的高度
          display: "flex",
          overflow: "hidden", // 隐藏溢出部分
          flexDirection: "column", // 垂直排列
          alignItems: "center", // 居中对齐
          justifyContent: "center", // 水平居中
        }}
      >
        <img src={u1z} alt="李佳奇UIUX作品集1" style={imageStyle} />
        <div // 导航栏
          style={{
            display: "flex",
            justifyContent: "center", // 水平居中子元素
            alignItems: "center", // 垂直居中（如果需要）
            width: "100%", // 父元素占满可用宽度
          }}
        >
          <div
            style={{
              backgroundColor: "#1F1F1F",
              maxWidth: `${maxWidth}px`,
              width: `${calculatedWidth}px`,
              height: "min(12vw,100px)", // 高度自动适应图片的高度
              //  display: "flex",
              alignItems: "center", // 垂直居中
              justifyContent: "center",
              overflow: "hidden", // 隐藏溢出部分
              display: "grid",
              gridTemplateColumns: "repeat(5, 1fr)", // 4等分网格
              position: "relative",
              padding: "0px 5vw",
              fontSize: "min(1.2vw, 10px)",
            }}
          >
            <motion.h
              whileHover={{ scale: 1.1 }} // 鼠标悬停时缩放到1.2倍
              whileTap={{ scale: 0.9 }}
              onClick={scrollToImageU2z}
              style={testStyle}
            >
              华为门户App亿家
            </motion.h>
            <h1 style={lineStyle}>/</h1>
            <motion.h1
              whileHover={{ scale: 1.1 }} // 鼠标悬停时缩放到1.2倍
              whileTap={{ scale: 0.9 }}
              onClick={scrollToImageU3z}
              style={testStyle}
            >
              腾讯QQ天气
            </motion.h1>
            <h1 style={lineStyle}>/</h1>
            <motion.h1
              whileHover={{ scale: 1.1 }} // 鼠标悬停时缩放到1.2倍
              whileTap={{ scale: 0.9 }}
              onClick={scrollToImageU4z}
              style={testStyle}
            >
              小度HMI
            </motion.h1>
          </div>
        </div>

        <img
          ref={imageU2z}
          src={u2z}
          alt="李佳奇UIUX作品集2"
          style={imageStyle}
        />
        <img
          ref={imageU3z}
          src={u3z}
          alt="李佳奇UIUX作品集3"
          style={imageStyle}
        />
        <img
          ref={imageU4z}
          src={h1}
          alt="李佳奇UIUX作品集4"
          style={imageStyle}
        />
      </div>
    </div>
  );
}

export default AppUIUX;
