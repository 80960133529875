import React from "react";
import { Link } from "react-router-dom"; // 导入Link组件
import { motion } from "framer-motion";
function NewHeader() {
  // Styles for the header
  const headerStyle = {
    backgroundColor: "#1F1F1F",
    height: "60px",
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    color: "#fff",
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 1000,
  };

  const backButtonStyle = {
    color: "#fff",
    textDecoration: "none",
    marginRight: "20px",
    width: 45,
  };

  return (
    <div style={headerStyle}>
      <Link to="/" style={backButtonStyle}>
        <motion.div
          whileHover={{ scale: 1.15 }} // 鼠标悬停时缩放到1.2倍
          whileTap={{ scale: 0.85 }}
        >
          返回
        </motion.div>
      </Link>
    </div>
  );
}
export default NewHeader;
