import React, { useState, useEffect } from "react";
import imagePath0 from "../img/m0.png"; // 导入图片路径
import imagePath1 from "../img/m1.mp4"; // 导入图片路径
import imagePath2 from "../img/m2.m4v"; // 导入图片路径
import imagePath3 from "../img/m3.mp4"; // 导入图片路径
import imagePath4 from "../img/m4.mp4"; // 导入图片路径
import imagePath5 from "../img/m5.mp4"; // 导入图片路径
import imagePath6 from "../img/m6.gif"; // 导入图片路径
import NewHeader from "./NewHeader"; // 使用正确的路径和文件名
import v7 from "../img/New_Horizon_Cinematic Flames.mp4"; // 导入图片路径
import v2 from "../img/New_Horizon_Fantasy_Logo.mp4"; // 导入图片路径
import v6 from "../img/New_Horizon_Live Nature English.mp4"; // 导入图片路径
import v3 from "../img/New_Horizon_Mountains Logo.mp4"; // 导入图片路径
import v4 from "../img/New_Horizon_Solid Logo Reveal_1080p .mp4"; // 导入图片路径
import v1 from "../img/New_Horizon_Space.mp4"; // 导入图片路径
import v5 from "../img/NewHorizon_Mountain Peaks Opener.mp4"; // 导入图片路径
import VT from "../img/Typhur.mp4"; // 导入图片路径
function Space() {
  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

function Motion() {
  useEffect(() => {
    // 创建 script 元素
    const script = document.createElement("script");
    script.src =
      "https://unpkg.com/@splinetool/viewer@0.9.493/build/spline-viewer.js";
    script.type = "module"; // 设置 script 类型为 module
    script.async = true; // 异步加载 script

    // 添加 script 到页面
    document.body.appendChild(script);

    // 在组件卸载时清理 script
    return () => {
      document.body.removeChild(script);
    };
  }, []); // 空依赖数组意味着这个 effect 只会在 mount 和 unmount 时运行

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // 在组件加载时滚动到页面顶部
    window.scrollTo(0, 0);
  }, []);

  const maxWidth = 1048;
  const widthPercentage = 94;
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  );

  const imageStyle = {
    maxWidth: `${maxWidth}px`,
    width: `${calculatedWidth}px`,
    height: "auto", // 高度自动适应宽度
  };

  return (
    <div>
      <NewHeader />
      <div>
        <div
          style={{
            position: "relative",
            top: 60,
            backgroundColor: "#1F1F1F",
            width: "100vw",
            height: "auto", // 高度自动适应图片和视频的高度
            display: "flex",
            flexDirection: "column", // 垂直排列
            alignItems: "center", // 水平居中对齐
            justifyContent: "flex-start", // 顶对齐
            overflow: "hidden", // 隐藏溢出部分
          }}
        >
          <img src={imagePath0} alt="Motion" style={imageStyle} />
          <Space />
          <video controls src={imagePath1} alt="Video" style={imageStyle} />
          <Space />
          <video controls src={imagePath4} alt="Video" style={imageStyle} />
          <Space />
          <video controls src={imagePath2} alt="Video" style={imageStyle} />
          <Space />
          <video controls src={VT} alt="Video" style={imageStyle} />
          <Space />
          <video controls src={imagePath3} alt="Video" style={imageStyle} />
          <Space />
          <video controls src={v1} alt="Video" style={imageStyle} />
          <Space />
          <video controls src={v2} alt="Video" style={imageStyle} />
          <Space />
          <video controls src={v3} alt="Video" style={imageStyle} />
          <Space />
          <video controls src={v4} alt="Video" style={imageStyle} />
          <Space />
          <video controls src={v5} alt="Video" style={imageStyle} />
          <Space />
          <video controls src={v6} alt="Video" style={imageStyle} />
          <Space />
          <video controls src={v7} alt="Video" style={imageStyle} />
          <Space />
          <img controls src={imagePath6} alt="GIF" style={imageStyle} />
          <Space />
          <video
            controls
            src={imagePath5}
            alt="Video"
            style={{ ...imageStyle, maxWidth: "480px" }}
          />
          <Space />
          {/*      <img
            controls
            src={imagePath8}
            alt="GIF"
            style={{ ...imageStyle, maxWidth: "480px" }}
          />
          <Space />
          <img
            controls
            src={imagePath9}
            alt="GIF"
            style={{ ...imageStyle, maxWidth: "480px" }}
          />
          <Space />
     <video
            controls
            src={imagePath10}
            alt="Video"
            style={{ ...imageStyle, maxWidth: "480px", borderRadius: "68px" }}
          />
          <Space /> */}
        </div>
      </div>
    </div>
  );
}

export default Motion;
