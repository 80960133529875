import React, { useEffect } from "react";

function New() {
  useEffect(() => {
    // 创建 script 元素
    const script = document.createElement("script");
    script.src =
      "https://unpkg.com/@splinetool/viewer@0.9.493/build/spline-viewer.js";
    script.type = "module"; // 设置 script 类型为 module
    script.async = true; // 异步加载 script

    // 添加 script 到页面
    document.body.appendChild(script);

    // 在组件卸载时清理 script
    return () => {
      document.body.removeChild(script);
    };
  }, []); // 空依赖数组意味着这个 effect 只会在 mount 和 unmount 时运行

  useEffect(() => {
    // 在组件加载时滚动到页面顶部
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <spline-viewer url="https://prod.spline.design/yFYdJB8-8Fon2M-G/scene.splinecode"></spline-viewer>
    </div>
  );
}

export default New;
