import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import wzh2b from "../img/wzh2b.jpeg"; // 导入图片路径
import wzh2c from "../img/wzh2c.jpeg"; // 导入图片路径
import wz1 from "../img/wz1.jpeg"; // 导入图片路径
import wzm from "../img/wzm.png"; // 导入图片路径
import wzo from "../img/wzo.jpeg"; // 导入图片路径
import imagePath1 from "../img/e.png"; // 导入图片路径
import imagePath2 from "../img/1101.png"; // 导入图片路径

function NewHeader() {
  // Styles for the header
  const headerStyle = {
    backgroundColor: "#1F1F1F",
    height: "60px",
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    color: "#fff",
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 1000,
  };

  const backButtonStyle = {
    color: "#fff",
    textDecoration: "none",
    marginRight: "20px",
    width: 45,
  };

  const downloadButtonStyle = {
    position: "fixed",
    right: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "30px",
    padding: "0 15px",
    backgroundColor: "#333", // Blue background color
    color: "#fff", // White text color
    border: "none", // No border
    borderRadius: "5px", // Rounded corners
    cursor: "pointer", // Pointer cursor on hover
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Slight shadow for depth
    transition: "background-color 0.3s", // Smooth transition for hover effect
    fontSize: "16px", // Font size
    fontWeight: "bold", // Bold font
  };
  const downloadTextStyle = {
    position: "fixed",
    right: "100px", // Adjust this value as needed to position the text
    height: "30px",
    display: "flex",
    alignItems: "center",
    color: "#fff", // White text color
    fontSize: "16px", // Font size
  };

  // Button style inside the button element
  const buttonInnerStyle = {
    backgroundColor: "transparent",
    border: "none",
    color: "inherit",
    padding: 0,
    cursor: "pointer",
    height: "100%", // Ensure the button fills the container height
  };

  // Style for the outermost div
  const outerDivStyle = {
    height: "60px",
    width: "100%",
    display: "flex",
    alignItems: "center", // This ensures vertical centering
    justifyContent: "space-between", // This spreads out the span and button div
    padding: "0 20px", // Adjust padding as needed
  };

  const downloadLinkRef = useRef(null);

  // Assuming you have another URL along with imagePath1
  const DownloadUrl = imagePath1;
  const anotherUrl = imagePath2; // Replace with your second file URL

  const handleDownloadLogo = () => {
    if (!DownloadUrl || !anotherUrl) {
      //console.error("URLs are not defined.");
      return;
    }

    // Function to create and click a download link
    const downloadFile = (url, filename) => {
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link); // Append to body
      link.click(); // Simulate click
      document.body.removeChild(link); // Remove from body
    };

    // Download first file
    downloadFile(DownloadUrl, "李佳奇作品集Ecommerce1");

    // Download second file
    downloadFile(anotherUrl, "李佳奇作品集Ecommerce2"); // Replace 'AnotherFile' with your desi#fff filename
  };

  return (
    <div style={headerStyle}>
      <Link to="/" style={backButtonStyle}>
        返回
      </Link>
      <div style={outerDivStyle}>
        <span style={downloadTextStyle}>本页HD作品集</span>
        <div style={downloadButtonStyle}>
          <button onClick={handleDownloadLogo} style={buttonInnerStyle}>
            下载
          </button>
        </div>
      </div>
      <a
        href={downloadLinkRef}
        style={{ display: "none" }}
        aria-label="Download"
      />
    </div>
  );
}

function Ecommerce() {
  const imageSectionStyle = {
    backgroundColor: "#1F1F1F",
    width: "100vw",
    height: "auto", // 高度自动适应图片的高度
    display: "flex",
    alignItems: "flex-start", // 图片顶对齐
    justifyContent: "center",
    overflow: "hidden", // 隐藏溢出部分
  };
  const testStyle = {
    cursor: "pointer",
    color: "#fff",
    borderRadius: "5px",
    textAlign: "center",
    whiteSpace: "nowrap",
    fontFamily: "SF",
    fontWeight: 400,
  };
  const lineStyle = {
    cursor: "pointer",
    color: "#666",
    borderRadius: "5px",
    textAlign: "center",
    fontFamily: "SF",
    fontWeight: 400,
  };
  // 创建单独的引用指向每张图片
  const imageRefWzh2c = useRef(null);
  const imageRefWzo = useRef(null);
  const imageRefWzm = useRef(null);
  const imageRefWzh2b = useRef(null);
  // 为每个链接创建滚动函数
  const scrollToImageWzh2c = () =>
    imageRefWzh2c.current?.scrollIntoView({ behavior: "smooth" });
  const scrollToImageWzo = () =>
    imageRefWzo.current?.scrollIntoView({ behavior: "smooth" });
  const scrollToImageWzm = () =>
    imageRefWzm.current?.scrollIntoView({ behavior: "smooth" });
  const scrollToImageWzh2b = () =>
    imageRefWzh2b.current?.scrollIntoView({ behavior: "smooth" });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // 在组件加载时滚动到页面顶部
    window.scrollTo(0, 0);
  }, []);

  const maxWidth = 1048;
  const widthPercentage = 94;
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  );

  const imageStyle = {
    maxWidth: `${maxWidth}px`,
    width: `${calculatedWidth}px`,
    height: "auto", // 高度自动适应宽度
  };

  return (
    <div>
      <NewHeader />
      <div
        style={{
          position: "relative",
          top: 60,
        }}
      >
        <div style={imageSectionStyle}>
          <img src={wz1} alt="webImage1" style={imageStyle} />
        </div>

        <div // 导航栏
          style={{
            display: "flex",
            justifyContent: "center", // 水平居中子元素
            alignItems: "center", // 垂直居中（如果需要）
            width: "100%", // 父元素占满可用宽度
          }}
        >
          <div
            style={{
              backgroundColor: "#1F1F1F",
              maxWidth: `${maxWidth}px`,
              width: `${calculatedWidth}px`,
              height: "min(12vw,100px)", // 高度自动适应图片的高度
              alignItems: "center", // 垂直居中
              justifyContent: "center",
              overflow: "hidden", // 隐藏溢出部分
              display: "grid",
              gridTemplateColumns: "repeat(7, 1fr)", // 4等分网格
              position: "relative",
              padding: "0px 5vw",
              fontSize: "min(1.2vw, 10px)",
            }}
          >
            <motion.h1
              whileHover={{ scale: 1.1 }} // 鼠标悬停时缩放到1.2倍
              whileTap={{ scale: 0.9 }}
              onClick={scrollToImageWzh2b}
              style={testStyle}
            >
              华为ToB电商
            </motion.h1>
            <h1 style={lineStyle}>/</h1>
            <motion.h1
              whileHover={{ scale: 1.1 }} // 鼠标悬停时缩放到1.2倍
              whileTap={{ scale: 0.9 }}
              onClick={scrollToImageWzm}
              style={testStyle}
            >
              美国个护商电商
            </motion.h1>
            <h1 style={lineStyle}>/</h1>
            <motion.h1
              whileHover={{ scale: 1.1 }} // 鼠标悬停时缩放到1.2倍
              whileTap={{ scale: 0.9 }}
              onClick={scrollToImageWzo}
              style={testStyle}
            >
              欧洲个护商电商
            </motion.h1>
            <h1 style={lineStyle}>/</h1>
            <motion.h1
              whileHover={{ scale: 1.1 }} // 鼠标悬停时缩放到1.2倍
              whileTap={{ scale: 0.9 }}
              onClick={scrollToImageWzh2c}
              style={testStyle}
            >
              华为ToC电商
            </motion.h1>
          </div>
        </div>

        <div style={imageSectionStyle}>
          <img
            ref={imageRefWzh2c}
            src={wzh2c}
            alt="华为ToC电商"
            style={imageStyle}
          />
        </div>
        <div style={imageSectionStyle}>
          <img
            ref={imageRefWzm}
            src={wzm}
            alt="美国个护商电商"
            style={imageStyle}
          />
        </div>
        <div style={imageSectionStyle}>
          <img
            ref={imageRefWzh2b}
            src={wzh2b}
            alt="华为ToB电商"
            style={imageStyle}
          />
        </div>
        <div style={imageSectionStyle}>
          <img
            ref={imageRefWzo}
            src={wzo}
            alt="欧洲个护商电商"
            style={imageStyle}
          />
        </div>
      </div>
    </div>
  );
}

export default Ecommerce;
